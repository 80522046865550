<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-row>
      <v-col cols="12" md="12">
        <v-card>
          <v-toolbar dense flat>
            <v-toolbar-title>Prodotti farmaceutici</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon>
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </v-toolbar>
          <v-divider></v-divider>


          <v-row>
            <v-col cols="12">
              <v-btn class="mr-2 ml-2" color="primary" dark rounded  @click="editProduct" >
                <v-icon>mdi-pencil-outline</v-icon>
                <span class="d-none d-md-block">Edit Product</span>
              </v-btn>
              <v-btn class="mr-2 ml-2" color="primary" dark rounded  @click="bulkUpdate" >
                <v-icon>mdi-plus</v-icon>
                <span class="d-none d-md-block">Bulk Update to CS-cart</span>
              </v-btn>

            </v-col>
          </v-row>
          <v-card-title>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
            ></v-text-field>
          </v-card-title>

          <v-card-text>

            <v-data-table
                :headers="headers"
                :items="rows"
                :items-per-page="50"
                v-model="selected"
                selectable-key="selected"
                :single-select="singleSelect"
                item-key=".key"
                show-select
                :search="search"
                class="elevation-1"
            ></v-data-table>

          </v-card-text>
        </v-card>
      </v-col>

    </v-row>



  </v-container>

</template>

<script>
import moment from "moment";
import { db } from '../../firebase';

export default {
  name: "PharmacyProducts",
  data: () => ({
    search: '',
    minsan: '',
    singleSelect: true,
    overlay: false,
    dialog: false,
    valid: false,
    product: '',
    product_code: '',
    amount: 1,
    main_category: '',
    price: '',
    selected: [],
    current: {},
    headers: [

      {
        text: 'MINSAN',
        value: 'minsan',
      },
      {
        text: 'Prodotto',
        value: 'product_name',
      },
      {
        text: 'CS-id',
        value: 'product_id',
      },
      {
        text: 'Categoria',
        value: 'category',
      },
      {
        text: 'Sotto Categoria',
        value: 'subcategory',
      },

    ],
    page: {
      title: "Farmacy Products"
    },
    elementVisible: false,
    breadcrumbs: [
      {
        text: "farmacy_products",
        disabled: true
      }
    ],
    rows: []
  }),
  mounted() {

    //console.log("here 242", this.$route.params)

    this.$binding("rows", db
        .collection("farmacie")
        .doc(this.$route.params.id)
        .collection('products_completed').limit(500))




    // this.$store.dispatch('pharmacyProducts/fetchById', this.$route.params.id).then((result)=> {
    //     if(result.products && result.products.length) {
    //           result.products.map((object, index)=> {
    //               object.id = index + 1;
    //               object.created_at =  this.getDisplayDate(result.created_at);
    //               object.updated_at = (result.updated_at)? this.getDisplayDate(result.updated_at) : '';
    //               return object;
    //           });
    //       this.rows = result.products;
    //     }
    // }).catch((error) =>{
    //     console.log('err', error)
    // });
  },
  methods: {
    getDisplayDate(dateObject) {
      let jsDate = new Date(dateObject.seconds * 1000);
      return moment(jsDate).format('DD/MM/YYYY');
    },
    editProduct () {
      if (this.selected.length) {
        //console.log("this.selected 272",this.selected);
        this.$router.push( {name: 'Product_Detail', params: { data: this.selected[0], pharmacy_id: this.$route.params.id }})
      } else {
        // this.showNotificationshowNotification('Please select a product first.', false);
      }
    },

    bulkUpdate(){
      alert("Disabled ")
    },
  }
};
</script>
